import React  from "react"

// components
import Template from "../../components/Template.js"

const mobility = () => {
  return (
    <div>
      <Template index={0} ></Template>
    </div>
  )
}

export default mobility